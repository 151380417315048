<template>
  <div class="stack-context-menu">
    <template v-if="menu.length && menu[0].length">
      <div v-for="(group, i) in menu" :key="i" class="stack-context-menu__group">
        <component
          :is="item.attrs ? 'a' : 'div'"
          v-for="item in group"
          :key="item.key"
          v-bind="item.attrs"
          class="stack-context-menu__item standart-title"
          :class="{ [`stack-context-menu__item--${item.color}`]: !!item.color }"
          :disabled="item.disabled"
          @click="onClick(item)"
        >
          {{ $t(`actions.${item.key}`) }}
        </component>
      </div>
    </template>
    <template v-else>
      <div class="stack-context-menu__noact standart-text">
        <div v-if="isSuspended" class="standart-title">
          {{ $t('noact.suspended') }}
        </div>
        <div>{{ $t('noact.noactions') }}</div>
      </div>
    </template>
  </div>
</template>

<script>
import showErrorModal from '@/mixins/showErrorModal';
import serialize from '@/utils/serialize';
import BaseLoader from '@/components/BaseLoader/BaseLoader';
import newDiskFromImage from '@/mixins/newDiskFromImage';
import renameImage from '@/mixins/renameImage';
import changeOs from '@/mixins/changeOs';
import { dev } from '@/layouts/LoginPage/mixins/currentProvider';
export default {
  name: 'ImageContextMenu',
  mixins: [showErrorModal, newDiskFromImage, renameImage, changeOs],
  props: {
    tariff: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      res: '',
      timerId: '',
      timerCnt: 0,
      isProcessing: false,
    };
  },
  computed: {
    serverState() {
      return this.tariff.status;
    },
    price() {
      return this.$store.state.moduleStack.price.length > 0
        ? this.$store.state.moduleStack.price.find(x => x.intname === 'image')[dev]
        : null;
    },
    cost() {
      return this.price ? this.price : 0;
    },
    status() {
      return this.tariff.status.code;
    },
    projectId() {
      return this.$store.getters['moduleStack/project_id'];
    },
    date() {
      return this.$d(Date.now(), 'short').replaceAll('.', '_');
    },
    time() {
      return this.$d(Date.now(), 'long').replace(/[^\d]/g, '_');
    },
    id() {
      return this.tariff.id;
    },
    name() {
      return this.tariff.name;
    },
    processingStatus() {
      return 2;
    },
    isSuspended() {
      return this.tariff.abusesuspend === 'on' || this.tariff.employeesuspend === 'on';
    },
    menu() {
      const status = this.serverState.code;

      const arr = [];
      arr.push([
        {
          key: 'disk',
          handler: 'disk',
          action: {
            volume: {
              size:
                this.tariff.min_disk > Math.ceil(this.tariff.size / Math.pow(1024, 3))
                  ? this.tariff.min_disk
                  : Math.ceil(this.tariff.size / Math.pow(1024, 3)),
              imageRef: this.id,
            },
          },
        },
        {
          key: 'rename',
          handler: 'rename',
          action: {
            size: Math.ceil(this.tariff.size / Math.pow(1024, 3)),
            minsize: this.tariff.min_disk,
            name: this.tariff.name,
          },
          disabled: !(![12, 13, 37].includes(status) || !this.isBoot),
        },
        {
          key: 'change',
          handler: 'change',
          action: {
            os: this.tariff.os_type,
            id: this.id,
          },
          disabled: !(![12, 13, 37].includes(status) || !this.isBoot),
        },
        {
          key: 'delete',
          color: 'del',
          handler: 'delete',
          action: { id: this.id },
        },
      ]);
      return arr;
    },
  },
  mounted() {
    if (this.processingStatus === 1) this.updateStatus();
  },
  beforeDestroy() {
    if (this.timerId) clearTimeout(this.timerId);
  },
  methods: {
    onClick(item) {
      if (item.disabled) return;
      if (item.action && item.handler === 'rename') this.renameImage(item.action);
      if (item.action && item.handler === 'change') this.changeOs(item.action);
      if (item.action && item.handler === 'delete') this.deleteImage(item);
      if (item.action && item.handler === 'disk') this.newDiskFromImage(item.action);
    },
    askSure(action) {
      const that = this;
      let self;
      const selfName = 'ServerAction';
      let res = '';
      let time;
      this.$modals.open({
        name: selfName,
        text: this.$t('modal.sure.text', { msg: this.$t(`actions.${action.key}`) }),
        onOpen(inst) {
          self = inst;
        },
        footer: {
          confirm: {
            props: {
              title: this.$t('modal.sure.confirm'),
            },
            on: {
              click: () => {
                self.text = null;
                self.component = BaseLoader;
                self.closable = false;
                self.footer = false;
                time = Date.now();
                this.isProcessing = true;
                // action.key === 'snapshot'
                this.createSnapshot(JSON.stringify(action.action))
                  .then(() => {
                    res = 'success';
                  })
                  .catch(() => {
                    res = 'fail';
                  })
                  .finally(() => {
                    const delay = Date.now() - time < 1000 ? 1000 : 0;
                    setTimeout(() => {
                      self.component = null;
                      self.closable = true;
                      self.text = this.$t(`modal.sure.${res}`);
                      self.footer = {
                        centered: true,
                        confirm: {
                          props: { title: this.$t('modal.sure.close') },
                          on: {
                            click: () => {
                              this.$modals.close();
                              this.$store.dispatch(
                                'moduleStack/fetchSnapshotsServer',
                                this.projectId
                              );
                            },
                          },
                        },
                      };
                    }, delay);
                  });
              },
            },
          },
          cancel: {
            on: {
              click: () => {
                that.$modals.close();
              },
            },
          },
        },
      });
    },
    async checkStatus(callback) {
      this.timerId = setTimeout(() => {
        this.timerCnt = 1;
        this.$store
          .dispatch('moduleStack/checkSnapshotStatus', { id: this.id, silent: true })
          .then(async data => {
            if (data !== 'ACTIVE') {
              await this.update(callback);
            } else {
              this.timerCnt = 0;
              this.isProcessing = false;
              clearTimeout(this.timerId);
            }
          });
      }, 1000 * this.timerCnt);
    },
    async deleteCurrentImage(image) {
      return this.$store.dispatch('moduleStack/deleteImage', {
        image: image,
      });
    },
    async deleteImage() {
      const that = this;
      let self;
      const selfName = 'ServerAction';
      let res = '';
      // console.log(item);
      let time;
      this.$modals.open({
        name: selfName,
        html: true,
        text: this.$t('delete', {
          name: this.tariff.name,
          id: this.tariff.id,
          cost: this.$n((this.cost * this.tariff.size) / 1024 ** 3, 'currency', this.$i18n.locale),
        }),
        onOpen(inst) {
          self = inst;
        },
        footer: {
          confirm: {
            props: {
              title: this.$t('modal.sure.confirm'),
              color: 'error',
            },
            on: {
              click: () => {
                self.text = null;
                self.component = BaseLoader;
                self.closable = false;
                self.footer = false;
                time = Date.now();
                this.isProcessing = true;
                this.deleteCurrentImage(this.id)
                  .then(async () => {
                    // console.log(data);
                    await this.$store.dispatch('moduleStack/fetchPrivateImages');
                    res = 'success';
                  })
                  // .then(() => {})
                  .catch(e => {
                    this.showError(e);
                    res = 'fail';
                  })
                  .finally(() => {
                    const delay = 2000;
                    setTimeout(() => {
                      self.component = null;
                      self.closable = true;
                      // console.log(res);
                      self.text = this.$t('modal.sure.success');
                      self.footer = {
                        centered: true,
                        confirm: {
                          props: { title: this.$t('modal.sure.close') },
                          on: {
                            click: () => {
                              this.$modals.close();
                              // this.fetchServer();
                              // this.$store.dispatch('moduleStack/fetchPrivateImages');
                            },
                          },
                        },
                      };
                    }, delay);
                  });
                // const delay = Date.now() - time < 1000 ? 1000 : 0;
              },
            },
          },
          cancel: {
            on: {
              click: () => {
                that.$modals.close();
              },
            },
          },
        },
      });
    },
    handleBillMgrTool(item) {
      if (item.tool.type === 'window') this.goToWindow(item);
      else if (item.tool.type === 'group') this.sendGroupTool(item);
      else throw new Error(`Handler for tool type ${item.tool.type} is not isset`);
    },
    goToWindow(item) {
      this.$store
        .dispatch('moduleStack/fetchBillMgrToolAction', {
          func: item.tool.func,
          elid: this.tariff.id,
        })
        .then(data => {
          if (data && data.ok && data.ok.type === 'url' && data.ok.v) {
            let payload = item.payload ? serialize(item.payload) : '';
            window.open(data.ok.v + payload);
          }
        });
    },
    sendServerAction(params) {
      return this.$store.dispatch('moduleStack/setServerAction', {
        id: this.tariff.id,
        params,
      });
    },
    createSnapshot(params) {
      return this.$store.dispatch('moduleStack/createSnapshot', {
        id: this.projectId,
        params,
      });
    },

    reboot() {
      return new Promise((resolve, reject) => {
        this.$store
          .dispatch('moduleVPS/moduleVpsOrder/setBackupAction', payload)
          .then(async response => {
            this.currentTaskUuid = response.Uuid;
            await this.updateBackupInfo();
            resolve('success');
          })
          .catch(reject => {
            this.showError(reject.response.data.message);
            this.$modals.close({ name: 'BackupModal' });
            this.$modals.close({ name: 'AddModal' });
          });
      });
    },
    async updateServerInfo(callback) {
      this.timerId = setTimeout(() => {
        this.$store
          .dispatch('moduleStack/updateList', { id: this.id, silent: true })
          .then(async data => {
            if (['REBOOT', 'HARD_REBOOT'].includes(data)) {
              this.res = data;
              await this.updateServerInfo(callback);
            } else {
              // console.log(data);
              this.timerCnt = 0;
              this.isProcessing = false;
              clearTimeout(this.timerId);
              this.res = data;
              return 'success';
            }
          });
      }, 1000 * this.timerCnt);
    },
    async updateStatus() {
      this.timerId = setTimeout(() => {
        this.$store
          .dispatch('moduleStack/updateList', { id: this.id, silent: true })
          .then(async data => {
            if (['REBOOT', 'HARD_REBOOT'].includes(data)) {
              if (this.timerCnt < 5) this.timerCnt += 1;
              this.res = data;
              await this.updateStatus();
            } else {
              this.res = data;
              this.timerCnt = 0;
              this.isProcessing = false;
              // this.oldState = '';
              clearTimeout(this.timerId);
            }
          });
      }, 1000 * this.timerCnt);
      return this.res;
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "delete": "Вы уверены, что хотите удалить образ:<br/> <b>%{name}</b> (%{id})? <br /> <br /> При этом ваши расходы уменьшатся на <b>%{cost}</b> в час.",
    "actions": {
      "disk": "Создать диск",
      "rename": "Изменить образ",
      "change": "Сменить ОС",
      "delete": "Удалить образ"
    },
    "prolong": {
      "confirm" : "Сменить",
      "order": "Отмена",
      "title": "Увеличить размер диска"
    },
    "noact": {
      "noactions": "Нет доступных действий.",
      "suspended": "Услуга остановлена администратором."
    },
    "sure": {
      "confirm": "Подтвердить"
    },
    "modal": {
      "sure": {
        "text": "Вы собираетесь %{msg} сервер. Вы уверены?",
        "confirm": "Да, уверен",
        "close": "Закрыть",
        "success": "Ваш образ успешно удалён",
        "fail": "Кажется, что-то пошло не так. Пожалуйста, попробуйте позже."
      }
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';

$vcm-colors = {
  primary: $primary-color,
  success: $success-color,
  del: $color-red.light,
}
$vcm-colors-hover = {
  primary: $color-blue.medium,
  success: darken($success-color, 20%),
  del: darken($color-red.light, 20%),
}

.stack-context-menu {

  &__group {
    & + & {
      margin-top: 2.5rem;

      +breakpoint(sm-and-up) {
        margin-top: (1.25rem * 3);
      }
    }
  }
  &__item {
    display: block;
    color: var(--text);
    text-decoration: none;
    cursor: pointer;
    transition: color 0.3s ease;

    &:first-letter {
      text-transform: uppercase;
    }

    &:focus {
      outline: none;
    }
    &:hover {
      color: $primary-color;
    }

    &[disabled] {
      pointer-events: none;
      cursor: default;
      opacity: 0.6;
    }

    & + & {
      margin-top: 1.25rem;
    }

    for $key, $value in $vcm-colors {
      &--{$key} {
        color: $value;
      }
    }
    for $key, $value in $vcm-colors-hover {
      &--{$key}:hover {
        color: $value;
      }
    }

  }

  &__noact {
    div + div {
      margin-top: 0.75rem;
    }
  }
}
</style>
<style lang="stylus">
@require '~@/assets/styles/vars/variables';

.stack-context-menu {
  .action-popup & {
    padding: 1.5rem 1rem;
    width: calc(100vw - 0.75rem);

    +breakpoint(sm-and-up) {
      padding: 1.5rem;
      width: 260px;
    }
  }
}
</style>
