<template>
  <base-table-row
    class="tariffs-table-item"
    :columns="columns"
    v-bind="item"
    col-class="tariffs-table-item__col"
  >
    <template #name>
      <text-highlight :queries="filtredSearch"> {{ item.name }}</text-highlight>
      <plain-button
        v-tooltip="{
          content: 'скопировать ссылку на скачивание образа в буфер обмена',
          placement: 'bottom-start',
          container: 'body',
        }"
        icon="copy-gray"
        size="medium"
        color="dim"
        tabindex="-1"
        @click="copyToClipboard()"
      />
      <div class="tariffs-table-item">
        <span class="tariffs-table-item__specs note-text">
          {{ item.id }}
        </span>
      </div>
    </template>
    <template #os>
      <div class="tariffs-table-item__ip">
        {{ item.os_type }}
      </div>
    </template>
    <!--    <template #id>-->
    <!--      <div class="tariffs-table-item__ip">-->
    <!--        <div class="hover">-->
    <!--          {{ item.id }}-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </template>-->
    <template #format>
      <div class="tariffs-table-item__ip">{{ item.disk_format }}</div>
    </template>
    <template #size>
      <div class="tariffs-table-item__ip">{{ size(item) }}</div>
    </template>
    <template #created>
      <div class="tariffs-table-item__ip">
        {{ created(item).split(' ')[0] }} <br />
        {{ created(item).split(' ')[1] }}
      </div>
    </template>
    <template #livecd>
      <tumbler :value="isRescue(item)" class="theme-switcher__tumbler" @change="setState" />
      <main-label
        :color="isRescue(item) ? 'success' : 'error'"
        theme="plain"
        class="tariffs-table-item__status"
      >
        <!--        {{ item.admin_state_up === true ? 'Выключить' : 'Включить' }}-->
        {{ isRescue(item) ? 'Выключить' : 'Включить' }}
      </main-label>
    </template>
    <template #status>
      <main-label
        :color="abortLoading ? 'error' : item.status.color"
        theme="plain"
        class="tariffs-table-item__status"
      >
        {{ $t(`status.${abortLoading ? 'queuedFile' : item.status.key}`) }}
      </main-label>
    </template>
    <template #more>
      <v-popover
        v-click-outside="closePopoverOnOutside"
        placement="left"
        :open="isPopoverOpen"
        trigger="manual"
        popover-class="tariff-table-item__popover"
      >
        <plain-button
          icon="more"
          class="tariffs-table-item__more"
          @click="togglePopoverState"
        /><template #popover>
          <image-context-menu :tariff="item" class="tariff-table-item__context-menu"
        /></template>
      </v-popover>
    </template>
  </base-table-row>
</template>

<script>
import BaseTableRow from '@/components/Table/BaseTableRow';
import MainLabel from '@/components/Labels/MainLabel.vue';
import ImageContextMenu from './ImageContextMenu';
import popoverFix from '@/mixins/popoverFix';
import Vue from 'vue';
import Clipboard from 'v-clipboard';
Vue.use(Clipboard);
import { format } from 'date-fns';
import Tumbler from '@/components/BaseTumbler/Tumbler';
export default {
  name: 'TariffsTableImageRow',
  components: {
    BaseTableRow,
    Tumbler,
    MainLabel,
    ImageContextMenu,
  },
  mixins: [popoverFix],
  props: {
    filtredSearch: {
      type: String,
      default: () => '',
    },
    columns: {
      type: Array,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    checked: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    linkToDownloadImage() {
      return `https://cloudapi.rusonyx.ru/image-download/${
        this.item.id
      }?token=${localStorage.getItem('stack')}`;
    },
    abortLoading() {
      return !!this.item.tags.find(x => x === 'file') && this.item.status.code === 39;
    },
    tags() {
      return this.item.tags;
    },
  },
  methods: {
    created(i) {
      return format(i.created_at, 'dd.MM.yyyy HH:mm:ss');
    },
    isRescue(item) {
      return !!item.tags.length && !!item.tags.find(x => x === 'rescue');
    },
    showSuccess(msg) {
      this.$modals.open({
        name: 'Success',
        // title: this.$t('success'),
        text: msg,
        footer: {
          centered: true,
          cancel: {
            props: { title: this.$t('ok') },
            on: { click: () => this.$modals.close() },
          },
        },
      });
    },
    printParam(param, payload) {
      return param.toString(payload);
    },
    isBootable(item) {
      return item.bootable === 'true' ? 'Загрузочный' : ' - ';
    },
    setState(value) {
      const tags = this.tags;
      if (value) tags.push('rescue');
      const modifyTags = value ? tags : this.tags.filter(x => x !== 'rescue');
      const newPayload = [
        {
          path: '/tags',
          value: modifyTags,
          op: 'replace',
        },

        {
          path: '/hw_rescue_bus',
          value: 'virtio',
          op: value ? 'add' : 'remove',
        },
        {
          path: '/hw_rescue_device',
          value: 'disk',
          op: value ? 'add' : 'remove',
        },
      ];
      if (this.item.disk_format === 'iso') {
        newPayload.push(
          {
            path: '/hw_cdrom_bus',
            value: 'ide',
            op: this.item['hw_cdrom_bus'] ? 'replace' : 'add',
          },
          {
            path: '/hw_disk_bus',
            value: 'ide',
            op: this.item['hw_disk_bus'] ? 'replace' : 'add',
          }
        );
      }
      newPayload.push({ id: this.item.id });

      this.$store
        .dispatch('moduleStack/renameImage', newPayload)
        .then(() => {
          // console.log(data);
        })
        .catch(e => {
          console.log(e);
        });
    },
    copyToClipboard() {
      this.$clipboard(this.linkToDownloadImage);
      this.showSuccess('Ссылка успешно скопирована в буфер обмена');
    },
    size(item) {
      return item.size / Math.pow(1024, 3) >= 1
        ? this.$t('gb', { num: (item.size / Math.pow(1024, 3)).toFixed(2) })
        : this.$t('gb', { num: (item.size / Math.pow(1024, 3)).toFixed(2) });
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "activeUnder": "До {date} г.",
    "mb": "{num} МБ",
    "gb": "{num} ГБ"
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.hover{
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
  position: relative;
  text-decoration: none;
  white-space: nowrap;
  background: var(--main-card-bg);
  z-index: 2
  &:hover {
    background: var(--main-card-bg);
    text-overflow: unset;
    overflow: visible;
    display inline
    z-index: 3;
  }
  &:hover::after {
    +breakpoint(ml-and-up) {
      content: '';
      //display: block;
      display: inline;
      size(2.5em, 100%);
      absolute(0px, 100%);
      z-index: 2
      background: linear-gradient(to right, var(--main-card-bg) 0%, transparent 100%);
    }
  }
}
.tariffs-table-item {
  font-size: 1rem;
  >>> &__col {
    vertical-align: middle;

    +breakpoint(md-and-down) {
      min-width: min-content;
      max-width: unset!important;
    }

    +breakpoint(ml-and-up) {
      text-overflow: ellipsis;
      overflow: hidden;
      position: relative;
      z-index: 1;
    }

    &:hover {
      background: var(--main-card-bg);
      text-overflow: unset;
      overflow: visible;
      z-index: 2;
    }
  }
  &__link {
    position: relative;
    padding: 0.5em;
    margin: -0.5em;
    color: $primary-color;
    text-decoration: none;
    white-space: nowrap;
    background: inherit;
    &:hover {
      color: darken($primary-color, 20%);
    }

    &:hover::after {
      +breakpoint(ml-and-up) {
        content: '';
        display: block;
        size(2.5em, 100%);
        absolute(0px, 100%);
        background: linear-gradient(to right, var(--main-card-bg) 0%, transparent 100%);
      }
    }
  }
}
</style>

<style lang="stylus">
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.tariff-table-item {
  &__popover {
    text-align: left
    &.tooltip[x-placement^="left"] {
      +breakpoint(ms-and-down) {
        margin-right: 0;
      }
    }
    &.tooltip[x-placement^="right"] {
      +breakpoint(ms-and-down) {
        margin-left: 0;
      }
    }
    .popover-arrow {
      +breakpoint(ms-and-down) {
        display: none;
      }
    }
  }

  &__context-menu {
    +breakpoint(ms-and-down) {
      width: calc(100vw - 6rem);
    }
  }
}
</style>
